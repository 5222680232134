<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">招聘模块</a>
          <i>></i>
          <a href="javascript:;" @click="pageBack">人才库</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">{{userName}} -- 职业技能</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start; ">
          <div class="searchbox" style="margin-bottom:5px">
            <div class="btnBox" style="margin-left:20px">
<!--              <el-button style="margin-left:20px" class="bgc-bv" round @click="getData()">查询</el-button>-->
              <el-button style="margin-left:20px" class="bgc-bv" round @click="showUpdateData()">新增职业技能</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
                ref="table"
                :data="tableData"
                :height="tableHeight"
                size="small"
                tooltip-effect="dark"
                style="width: 100%"
                :header-cell-style="tableHeader"
                stripe
            >
              <el-table-column
                  label="序号"
                  align="center"
                  type="index"
                  width="100px"
                  :index="indexMethod"
              />
              <el-table-column
                  label="技能名称"
                  align="left"
                  prop="skillName"
                  show-overflow-tooltip
                  min-width="100"
              />
              <el-table-column
                  label="熟练程度"
                  align="left"
                  prop="degreeProficiency"
                  show-overflow-tooltip
                  min-width="100"
              >
                <template slot-scope="{ row }">
                  {{ $setDictionary("HR_DEGREE_PROFICIENCY", row.degreeProficiency) }}
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="240px" fixed="right">
                <div slot-scope="{ row }">
                  <el-button type="text"
                      style="padding:0px 5px"
                      size="mini"
                      @click="showUpdateData(row)"
                  >编辑</el-button>
                  <el-button
                      type="text"
                      style="padding:0px 5px"
                      size="mini"
                      @click="deleteData(row.vocationalSkillId)"
                  >删除</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
    <el-dialog
        :title="dialogTitle"
        :visible.sync="dialogVisible"
        width="60%"
        :before-close="handleClose">
      <div>
<!--        <h3 style="padding: 0 0 20px 0;">沟通记录</h3>-->
        <el-form
            :model="dataForm"
            ref="dataForm"
            :rules="dataRules"
            label-width="150px"
        >
                <el-form-item label="技能名称：" prop="skillName">
                  <el-input v-model="dataForm.skillName" size="small" placeholder="请输入技能名称"></el-input>
                </el-form-item>
                <el-form-item label="熟练程度：" prop="degreeProficiency">
                  <el-select
                      size="small"
                      v-model="dataForm.degreeProficiency"
                      clearable
                  >
                    <el-option
                        v-for="item in degreeProficiencyList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>


        </el-form>
      </div>
      <span slot="footer" class="dialog-footer" style="width: 100%">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="saveData">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "vocationalSkills",
  components: {
    Empty,
    PageNum
  },
  mixins: [List],
  data() {
    return {
      talentId: this.$route.query.talentId,
      userName: this.$route.query.userName,

      areaList: [], // 期望城市List
      salaryRequireList: [], // 薪资要求List

      dialogTitle: '',
      dialogVisible: false, //新增编辑弹窗开关

      dataForm: {
        vocationalSkillId: "",
        skillName: "",
        degreeProficiency: "",
      },

      degreeProficiencyList: [], // 熟练程度List

      dataRules: {
        skillName: [{ required: true, message: "请输入技能名称", trigger: "blur" }],
      },
      retrievalData:{},
      currentPage:''
    };
  },
  computed: {},
  created() {
    if(this.$route.query.retrievalData){
      this.retrievalData = this.$route.query.retrievalData
    }
    if(this.$route.query.currentPage){
      this.currentPage = this.$route.query.currentPage
    }
    this.getDropDownData()
  },
  methods: {
    // 返回上一页
    pageBack(){
      this.$router.push({
        path: "/hr/recruitModule/personnelLibrary/personnelList",
        query: {
          retrievalData: this.retrievalData,
          currentPage: this.currentPage
        },
      });
    },
    // 获取 all- 下拉数据
    getDropDownData() {
     // 薪资要求list
      const degreeProficiencyList = this.$setDictionary(
          "HR_DEGREE_PROFICIENCY",
          "list"
      );
      for (const key in degreeProficiencyList) {
        this.degreeProficiencyList.push({
          value: key,
          label: degreeProficiencyList[key],
        });
      }
    },
    // 获取列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        talentId: this.talentId
      };
      this.doFetch({
        url: "/hr/talent/info/vocationalSkillLogPage",
        params,
        pageNum
      });
    },
    // 打开新增&编辑
    showUpdateData(row){
      this.dialogVisible = true
      let str = '职业技能'
      this.$nextTick(()=>{
        this.$refs["dataForm"].resetFields();
        this.dataForm = {
          vocationalSkillId: "",
          skillName: "",
          degreeProficiency: "",
        }
        if(row){
          this.dialogTitle = '编辑' + str
          this.dataForm = {
            ...row
          }
          // this.dataForm.itemTime = [ row.beginTime.substring(0,10), row.endTime.substring(0,10) ]
          this.dataForm.vocationalSkillId = row.vocationalSkillId
        }else{
          this.dialogTitle = '新增' + str
          this.dataForm.vocationalSkillId = ""
        }
      })
    },
    // 关闭新增&编辑
    handleClose() {
      this.dialogVisible = false
    },
    // 弹层保存数据
    saveData(){
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          let params = {
            talentId: this.talentId,
            skillName: this.dataForm.skillName,
          }
          if(this.dataForm.vocationalSkillId != ""){
            params.vocationalSkillId = this.dataForm.vocationalSkillId
          }
          if(this.dataForm.degreeProficiency){
            params.degreeProficiency = this.dataForm.degreeProficiency
          }
          console.log(params)
          this.$post(
              "/hr/talent/info/saveOrUpdateVocationalSkill",
              params
          ).then((res) => {
            if (res.status == 0) {
              this.$message({
                message: res.message,
                type: "success",
              });
              this.dialogVisible = false
              this.getData(-1);
            }
          });
        }
      })
    },
    // 删除列表数据
    deleteData(id){
      let str = '职业技能'
      this.$confirm(
          "是否删除此条" + str,
          "提示",
          {
            confirmButtonText: "确定删除",
            cancelButtonText: "取消",
            type: "warning",
          }
      )
          .then(() => {
            let params = {
              // talentId: this.talentId,
              vocationalSkillId: id
            }
            console.log(params)
            this.$post(
                "/hr/talent/info/removeVocationalSkillLog",
                params
            ).then((res) => {
              if (res.status == 0) {
                this.$message({
                  message: res.message,
                  type: "success",
                });
                this.getData(-1);
              }
            });
          })
          .catch(() => {
            return;
          });
    },

  },
  beforeRouteLeave: resetKeepAlive,
  watch: {}
};
</script>
<style lang="less" scoped>
.el-rate__icon {
  margin-right: 0;
}
</style>
